@import "../../scss/mixins.scss";

.eventInfoIntro {
  @include component_min_height();
}

.image {
  width: 50%;
  margin: 0 auto;
}

.title {
  margin-top: 10px;
  margin-bottom: 50px !important;
}
