.shareModal {
  min-width: 500px;
  border-radius: 5px;
}

.submitButton {
  width: 100%;
}

.footer {
  padding: 20px;
}

.eventModalContent {
  background-color: #fff;
  padding: 0 20px !important;
  margin: unset !important;
}

@media (max-width: 768px) {
  .shareModal {
    min-width: 100%;
    max-height: 75% !important;
    border-radius: 5px;
    padding: 20px;
  }
}