.signin {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 24px;
  padding-bottom: 20px;
  width: 300px;
}

.googleBtn {
  background-color: #4285f4 !important;
}

.signInPrompt {
  margin-bottom: 40px;
  color: #000;
}