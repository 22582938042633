.dropDown {
  position: absolute;
  right: 24px;
  top: 64px;
  z-index: 999;

  width: 300px;
  padding: 0 0 20px !important;

  background-color: white;
}

.item {
  padding: 0.5rem;
}

.avaratFigure {
  width: 80px;
  margin: 20px auto;
}

.displayName {
  color: #202124;
  font-weight: 600;
  font-size: 18px;
}

.email {
  margin-top: -3px;
  font-size: 14px;
}

.signOutBtn {
  display: block !important;

  width: 70%;
  margin: 20px auto 0;
  text-align: center;

  font-weight: 500;
}

.showDropdown {
  display: block;
}

.hideDropdown {
  display: none !important;
}
