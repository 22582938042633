@import "../../../_variables.sass";

.inviteeForm {
  margin-bottom: 20px;
  padding: 20px;
}

.submit {
  width: 100%;
}

.status {
  width: 100% !important;
}

.eventModalForm {
  padding-top: 20px;
  padding-bottom: 20px;
}