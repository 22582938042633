.imgBtn {
  padding: 0 !important;
  background-size: cover;
  border-radius: 100%;
  height: 100%;
  cursor: pointer;
}

.border {
  border: 5px solid rgba(0, 0, 0, 0.3);
}
