.isMobileOnly {
    display: none;
  }

  @media (max-width: 1040px) {
    .isMobileOnly {
      display: block; /* Show on mobile */
      margin: 0 auto; /* Center the button */
      background-color: hsl(0, 0%, 21%);
    border-color: transparent;
    color: #fff;
    border-width: 1px;
    cursor: pointer;
    justify-content: center;
    padding-bottom: calc(0.5em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(0.5em - 1px);
    text-align: center;
    white-space: nowrap;
    margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 10px;
        margin-top: 16px;
        border: 1px solid rgb(221, 221, 221);
    border-radius: 8px;
    }
  }