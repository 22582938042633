.footer {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  background-color: #ddd !important;
}

.cardFooterItem {
  text-align: center;
  display: block !important;
  font-size: 15px;
  padding: 0px !important;
}