.eventModal {
  min-width: 500px;
  border-radius: 5px;
}

.submitButton {
  width: 100%;
  background-color: #363636 !important;
  color: #fff !important;
  font-weight: bold !important;
}

.footer {
  padding: 20px;
}

.inviteeModalContent {
  background-color: #fff;
  padding: 0 20px !important;
  margin: unset !important;
}


@media (max-width: 768px) {
  .eventModal {
    min-width: 100%;
    max-height: 75% !important;
    border-radius: 5px;
    padding: 20px;
  }
}