.logo {
  display: flex;
  align-items: center;
  padding: 6px 12px;
}

.logoTitle {
  font-weight: 900;
  font-size: 16px;
}

.logoImg {
  width: 30px;
  max-height: 30px !important;
  margin-right: 5px;
}

.logo {
  display: flex;
  align-items: center;
}

.logoImage {
  width: auto; /* Adjust size as needed */
  height: 40px;
  margin-right: 10px; /* Space between the image and title */
}

.logoTitle {
  font-size: 24px;
  font-weight: bold;
  color: #333; /* Adjust to your color preference */
  font-family: 'Arial', sans-serif;
  letter-spacing: 2px;
}

/* Optional: Add some hover effect */
.logoTitle:hover {
  color: #332; /* Change to your desired hover color */
}