.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 5px; /* Rounded corners (optional) */
  padding: 10px; /* Padding inside the ul */
  margin-bottom: 10px; /* Space below the ul */
  background-color: #f9f9f9; /* Light background color (optional) */
  
}

.guestInfo {
  display: flex;
  width: 80%;
  flex-direction: column;
}

.guestNote {
  font-size: 14px; /* Smaller font size */
  color: #737e87; /* Subdued color (gray) */
  margin: 4px 0 0; /* Small margin at the top */
}