.app {
  position: relative;
  width: 100%;
  background: #fff;
  overflow: hidden;
  min-height: calc(100vh - 60px);
}

.cardContent {
  padding: 0 !important;
}