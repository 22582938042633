.eventListItem {
  position: relative !important;

  display: flex !important;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px !important;
  cursor: pointer !important;
}
