/* .cardHeaderTitle {
    display: block;
} */

@media (max-width: 450px) {
    /* .header {
        display: flex;
        flex-direction: column;
      } */
      .cardHeaderTitle {
        display: block !important;
        text-align: center !important;
      }
      .isMobile {
        display: none !important;
      }
}
